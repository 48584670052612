import React, { FC } from 'react';
import s from './ModuleOne.module.scss'
import CardInfo from './Sections/CardInfo/CardInfo';
import Slider from './Sections/Slider/Slider';
import TargetAudience from './Sections/TargetAudience/TargetAudience';
import CourseOverview from './Sections/CourseOverview/CourseOverview';
import LearningFormat from './Sections/LearningFormat/LearningFormat';


const ModuleOne: FC = () => {
    return (
        <>
            <CardInfo />
            <Slider />
            <TargetAudience />
            <CourseOverview />
            <LearningFormat />
        </>
    );
};

export default ModuleOne;