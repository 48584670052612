import React, { FC } from 'react';
import s from './TargetAudience.module.scss'
import cardIcon from '../../../../assets/images/CardIcon.svg'

const TargetAudience: FC = () => {
    return (
        <section className={s.target_audience}>
            <h2 className={s.title}>
                Кому подойдет данный курс
            </h2>

            <div className={s.cards}>
                <div className={s.card}>
                    <img src={cardIcon} alt="cardIcon" />
                    <h2>Новички в трейдинге: </h2>
                    <p>Люди, которые только начинают свой путь в мире трейдинга и хотят научиться основам.</p>
                </div>
                <div className={s.card}>
                    <img src={cardIcon} alt="cardIcon" />
                    <h2>Новички в трейдинге: </h2>
                    <p>Люди, которые только начинают свой путь в мире трейдинга и хотят научиться основам.</p>
                </div>
                <div className={s.card}>
                    <img src={cardIcon} alt="cardIcon" />
                    <h2>Новички в трейдинге: </h2>
                    <p>Люди, которые только начинают свой путь в мире трейдинга и хотят научиться основам.</p>
                </div>
                <div className={s.card}>
                    <img src={cardIcon} alt="cardIcon" />
                    <h2>Новички в трейдинге: </h2>
                    <p>Люди, которые только начинают свой путь в мире трейдинга и хотят научиться основам.</p>
                </div>
            </div>
        </section>
    );
};

export default TargetAudience;