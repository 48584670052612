import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IFAQ, IMentors, INullable, IReviews } from "../types"
import { itsAPI } from "../../axios"

type HomeState = {
    loading: boolean
    error: INullable<string>
    mentors: IMentors[]
    reviews: IReviews[]
    faq: IFAQ[]
}

const initialState: HomeState = {
    error: null,
    loading: false,
    mentors: [],
    reviews: [],
    faq: [],
}

export const getAllMentors = createAsyncThunk<IMentors[], void, { rejectValue: string }>(
    'home/getAllMentors',
    async (_, { rejectWithValue }) => {
        try {
            const res = await itsAPI.mentors()
            if (res.status !== 200) {
                throw new Error('Server Error')
            }

            return res.data as IMentors[]
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

export const getAllReviews = createAsyncThunk<IReviews[], void, { rejectValue: string }>(
    'home/getAllReviews',
    async (_, { rejectWithValue }) => {
        try {
            const res = await itsAPI.reviews()
            if (res.status !== 200) {
                throw new Error('Server Error')
            }

            return res.data as IReviews[]
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

export const getFAQHome = createAsyncThunk<IFAQ[], void, { rejectValue: string }>(
    'home/getFAQHome',
    async (_, { rejectWithValue }) => {
        try {
            const res = await itsAPI.faqHome()
            if (res.status !== 200) {
                throw new Error('Server Error')
            }

            return res.data as IFAQ[]
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(getAllMentors.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(getAllMentors.fulfilled, (state, action) => {
            state.mentors = action.payload
            state.loading = false
        })
        addCase(getAllMentors.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload
            }
            state.loading = false
        })
        //=====================================================
        addCase(getAllReviews.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(getAllReviews.fulfilled, (state, action) => {
            state.reviews = action.payload
            state.loading = false
        })
        addCase(getAllReviews.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload
            }
            state.loading = false
        })
        //=====================================================
        addCase(getFAQHome.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(getFAQHome.fulfilled, (state, action) => {
            state.faq = action.payload
            state.loading = false
        })
        addCase(getFAQHome.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload
            }
            state.loading = false
        })
        //=====================================================
    }
})

export default homeSlice.reducer