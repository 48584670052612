import React, { FC } from 'react';
import Hero from './Sections/Hero/Hero';
import AboutUS from './Sections/AboutUS/AboutUS';
import WhyWe from './Sections/WhyWe/WhyWe';
import { LayoutGroup } from 'framer-motion';
import Forex from './Sections/Forex/Forex';
import Places from './Sections/Places/Places';
import Study from './Sections/Study/Study';
import Mentors from './Sections/Mentors/Mentors';
import Reviews from './Sections/Reviews/Reviews';
import FAQHome from './Sections/FAQHome/FAQHome';

const Home: FC = () => {
    return (
        <LayoutGroup id='home'>
            <Hero />
            <AboutUS />
            <WhyWe />
            <Forex />
            <Places />
            <Study />
            <Mentors />
            <Reviews />
            <FAQHome />
        </LayoutGroup>
    );
};

export default Home;