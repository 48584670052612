import axios from "axios";
import { IFeedbackUser } from "../redux/types";


const instance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: false,
})

export const itsAPI = {
    getAllInfo() {
        return instance.get(`/api/company_info/company_info/`)
    },
    feedback(userInfo: IFeedbackUser) {
        return instance.post(`/api/company_info/feedback/`, userInfo)
    },
    mentors() {
        return instance.get(`/api/mentors/mentors/`)
    },
    reviews() {
        return instance.get(`/api/reviews/reviews/`)
    },
    faqHome() {
        return instance.get(`/api/company_info/faq/`)
    }
}