import React, { FC } from 'react';
import s from './FAQ.module.scss';
import { Accordion, AccordionItem } from "@nextui-org/accordion";
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { IFAQ } from '../../redux/types';

interface FAQProps {
    arr: IFAQ[]
}

const FAQ: FC<FAQProps> = ({ arr }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: -100 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.5 }}
            className={s.wrapper}>
            <h2 className={s.title}>FAQ</h2>
            <Accordion variant="splitted">
                {
                    arr.map(el => (
                        <AccordionItem
                            key={el.id}
                            aria-label={el.question}
                            title={el.question}
                            className={s.accordionItem}
                            classNames={{
                                base: s.base,
                                heading: s.heading,
                                trigger: s.trigger,
                                title: s.title_accordion,
                                content: s.content,
                                indicator: s.indicator,
                            }}
                        >
                            {el.answer}
                        </AccordionItem>
                    ))
                }
            </Accordion>
        </motion.div >
    );
};

export default FAQ;