import React, { FC, useRef, useState } from 'react';
import s from './Slider.module.scss'
import { Swiper, SwiperRef, SwiperSlide, useSwiper } from 'swiper/react';
import SliderElement from './SliderElement/SliderElement';

const Slider: FC = () => {
    // const [activeIndex, setActiveIndex] = useState(0);
    // const swiperRef = useRef<SwiperRef>(null)

    // const handleSlideChange = () => {
    //     if (swiperRef.current) {
    //         setActiveIndex(swiperRef.current.swiper.realIndex);
    //     }
    // };


    return (
        <div>
            <h2 className={s.title}>Общие элементы программы</h2>
            <Swiper
                spaceBetween={20}
                slidesPerView={4}
            >
                <SwiperSlide><SliderElement /></SwiperSlide>
                <SwiperSlide><SliderElement /></SwiperSlide>
                <SwiperSlide><SliderElement /></SwiperSlide>
                <SwiperSlide><SliderElement /></SwiperSlide>
                <SwiperSlide><SliderElement /></SwiperSlide>
                <SwiperSlide><SliderElement /></SwiperSlide>
                <SwiperSlide><SliderElement /></SwiperSlide>
                <SwiperSlide><SliderElement /></SwiperSlide>
            </Swiper>


        </div>
    );
};

export default Slider;