import React, { FC } from 'react';
import s from './CardInfo.module.scss'

const CardInfo: FC = () => {
    return (
        <div className={s.card_bg} >
            <div className={s.module_info}>
                <div className={s.module_num}>
                    <p>Модуль 1</p>
                </div>

                <div className={s.duration}>

                    <div className={s.duration_item}>
                        <p>Продолжительность:<span> 1 месяц</span></p>
                    </div>

                    <div className={s.duration_item}>
                        <p>Количество уроков:<span> 20</span></p>
                    </div>
                </div>
            </div>

            <div className={s.info_card}>
                <h2>Трейдинг с нуля: первые шаги осознанности на бирже</h2>
                <p>Онлайн-обучение, на котором вы разберетесь в терминах,
                    самом рынке, принципах работы трейдера и подготовитесь
                    к реальной торговле на любых финансовых рынках</p>
                <button>Программа</button>
            </div>
        </div>
    );
};

export default CardInfo;