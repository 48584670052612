import React, { FC } from 'react';
import s from './LearningFormat.module.scss'

const LearningFormat: FC = () => {
    return (
        <section className={s.wrapper}>
            <h2 className={s.title}>Формат обучения</h2>

            <div className={s.info}>
                <div className={s.left_block}>
                    <h2 className={s.logo}>ITS</h2>
                    <p>Innovators Traiding System Platform</p>
                </div>
                <div className={s.right_block}>
                    <div className={s.card}>
                        <h3>Вебинары и онлайн встречи</h3>
                        <div className={s.info_items}>
                            <p>до 15</p>
                            <span>Практических заданий и тестов</span>
                        </div>
                    </div>
                    <div className={s.card}>
                        <h3>Вебинары и онлайн встречи</h3>
                        <div className={s.info_items}>
                            <p>до 15</p>
                            <span>Практических заданий и тестов</span>
                        </div>
                    </div>
                    <div className={s.card}>
                        <h3>Вебинары и онлайн встречи</h3>
                        <div className={s.info_items}>
                            <p>до 15</p>
                            <span>Практических заданий и тестов</span>
                        </div>
                    </div>
                    <div className={s.card}>
                        <h3>Вебинары и онлайн встречи</h3>
                        <div className={s.info_items}>
                            <p>до 15</p>
                            <span>Практических заданий и тестов</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LearningFormat;