import React, { FC } from 'react';
import s from './CourseOverview.module.scss'

const CourseOverview: FC = () => {
    return (
        <div>
            <h2 className={s.title}>Как будет проходить обучение</h2>
        </div>
    );
};

export default CourseOverview;