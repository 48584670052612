import React, { FC, useEffect } from 'react';
import TradeInfoMain from './TradeInfoMain/TradeInfoMain';
import ModuleInfoCard from './ModuleInfoCard/ModuleInfoCard';
import InfoColumn from './InfoColumn/InfoColumn';
import ModuleOne from '../ModuleOne/ModuleOne';
import { scrollToTop } from '../../utils';


const Program: FC = () => {

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <div className='container'>
            <TradeInfoMain />
            <ModuleInfoCard />
            <InfoColumn />

            <ModuleOne />
        </div>
    );
};

export default Program;