import React, { FC } from 'react';
import s from './SliderElement.module.scss'
import cardImg from '../../../img/slider_img.jpg'

const SliderElement: FC = () => {
    return (
        <div className={s.card}>
            <div>
                <img src={cardImg} alt="cardImg" />
            </div>
            <div className={s.info}>
                <h2>Онлайн обучение, на котором вы: </h2>
                <p>Изучите основы и принципы работы финансовых рынков:
                    от различия акций и облигаций до работы с основными
                    индексами.</p>
            </div>
        </div>
    );
};

export default SliderElement;